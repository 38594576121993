﻿import {createTextInputWithAutoComplete} from "./form-input";
import {expressQuerySelector} from "../common/html";
import {isValidTextWithoutAngleBrackets} from "../utils/validation";
import {initTooltip} from "./tooltip";

export function initSearchBar(desktopSearchBarContainer: HTMLElement, mobileSearchBarContainer: HTMLElement, autoCompleteSearch: (searchTerm: string) => Promise<string[]>, onOpen?: () => void, onClose?: () => void) {
	const initAutoComplete = (buttonEl: HTMLElement, inputEl: HTMLInputElement) => {
		const buttonDisabledClass = "a-button--disabled";
		const form = inputEl.parentElement! as HTMLFormElement;

		if (buttonEl) buttonEl.classList.add(buttonDisabledClass);

		const renderTextWithHighlights = (item: { value: string, label: string }, currentSearchterm: string) => {
			currentSearchterm = currentSearchterm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
			const re = new RegExp("(" + currentSearchterm.split(' ').join('|') + ")", "gi");
			return item.value.replace(re, "<b>$1</b>");
		};

		inputEl.onkeyup = function (ev: KeyboardEvent) {
			if (buttonEl) {
				if (inputEl && inputEl.value) buttonEl.classList.remove(buttonDisabledClass);
				else buttonEl.classList.add(buttonDisabledClass);
			}
			if (ev.keyCode === 13 || ev.key === 'Enter') {
				fixInputValue(inputEl);
				form.submit();
			}
		};

		form.onsubmit = function (e) {
			if (!inputEl || !inputEl.value || !validateInput(inputEl)) {
				e.preventDefault();
				return;
			}

			fixInputValue(inputEl);
		};
		return createTextInputWithAutoComplete (
			inputEl,
			{
				onSelect: () => { onClose && onClose();  form.submit(); },
				autoCompleteSearch: (searchTerm) => {
					if (validateInput(inputEl, searchTerm))
						return autoCompleteSearch(searchTerm).then(res => res.map(item => ({ value: item, label: item })));

					return;
				},
				onOpen: onOpen,
				onClose: onClose,
				renderTextContent: renderTextWithHighlights,
				containerClass: 'm-autocomplete autocomplete-suggestions m-search-bar__autocomplete',
				containerInsertPosition: 'afterend',
			}
		);
	};

	const fixInputValue = (inputEl: HTMLInputElement) => {
		inputEl.value = inputEl.value.replace(/[<>]+/g, '');
	};

	const validateInput = (inputEl: HTMLInputElement, searchTerm?: string) => {
		return isValidTextWithoutAngleBrackets(searchTerm ? searchTerm : inputEl.value);
	};

	const initMobileSearchBar = (containerEl: HTMLElement) => {
		const inputEl = expressQuerySelector<HTMLInputElement>(containerEl, ".m-search-bar__input", true);
		const buttonEl = expressQuerySelector<HTMLInputElement>(containerEl, ".icon-search_thin", true);
		const autoComplete = initAutoComplete(buttonEl, inputEl);
		const onSearchTooltipOpen = () => inputEl.focus();
		const form = inputEl.parentElement! as HTMLFormElement;

		form.onsubmit = function (e) {
			if (!inputEl || !inputEl.value || !validateInput(inputEl)) {
				e.preventDefault();
				return;
			}

			fixInputValue(inputEl);
		};

		const overlayComponent = initTooltip({
			buttonEl: containerEl.previousElementSibling as HTMLElement,
			containerEl: containerEl,
			onOpen: onSearchTooltipOpen,
			openClass: "menu-is-open",
			tooltipSelector: ".o-icon-navigation__search-bar",
			toggleIconSelector: ".icon-search_thin"
		});

		return {
			dipose: () => {
				overlayComponent.dispose();
				autoComplete.dispose();
			}
		};
	};

	// init desktop search autocomplete
	const desktopSearchBar = initAutoComplete (
		expressQuerySelector<HTMLInputElement> (
			desktopSearchBarContainer,
			'.icon-search_thin',
			true
		),
		expressQuerySelector<HTMLInputElement> (
			desktopSearchBarContainer,
			'.m-search-bar__input',
			true
		)
	);

	// init mobile search
	const mobileSearchBar = initMobileSearchBar(mobileSearchBarContainer);

	return {
		dispose: () => {
			mobileSearchBar.dipose();
			desktopSearchBar.dispose();
		}
	};
}
