import { ICollapsable } from "../collapsable";
import { expressEventListener, expressQuerySelector } from "../../common/html";
import { getParameter } from "../../utils/querystring";
import { TypeOfFilter } from "../../organisms/filters";
import { IFilterDependencies } from "../../organisms/filters";
import { onlyNumber } from "../../utils/validation";

export interface IRangeFilter {
	readonly removeRangeFilter: (update?: boolean) => void;
}

export function createRangeFilter(collapsableComponent: ICollapsable, selectedFiltersContainerEl: HTMLElement, filterQs: string, filterCategory: string, filterEl: HTMLElement, showSliderEls: HTMLInputElement[], filtersContainerEl: HTMLElement, deps: IFilterDependencies): IRangeFilter {
	const {
		updateParametersForUrl,
		removeFromFilterContainer,
		onFilter,
		attachRemoveFilterEvent,
		toggleResetFilters,
		addTextToFilterContainer
	} = deps;
	const outOfBoundsMinBarEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-pre-bar", true);
	const outOfBoundsMaxBarEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-post-bar", true);
	const sliderMinRangeInputEl = expressQuerySelector<HTMLInputElement>(filterEl, ".a-form-input__slider-range--min", true);
	const sliderMaxRangeInputEl = expressQuerySelector<HTMLInputElement>(filterEl, ".a-form-input__slider-range--max", true);
	const sliderMinRangeThumbEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-thumb--min", true);
	const sliderMaxRangeThumbEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-thumb--max", true);

	const containerEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-container", true);
	const containerMinSliderTextInputEl = expressQuerySelector<HTMLElement>(containerEl, ".a-form-input__slider-bubble-container--min", true);
	const containerMaxSliderTextInputEl = expressQuerySelector<HTMLElement>(containerEl, ".a-form-input__slider-bubble-container--max", true);
	const sliderMinTextInputEl = expressQuerySelector<HTMLInputElement>(containerMinSliderTextInputEl, ".a-form-input__slider-bubble--min", true);
	const sliderMaxTextInputEl = expressQuerySelector<HTMLInputElement>(containerMaxSliderTextInputEl, ".a-form-input__slider-bubble--max", true);

	const originalMinValue = sliderMinRangeInputEl.min;
	const originalMaxValue = sliderMaxRangeInputEl.max;
	let currentMinValue = sliderMinRangeInputEl.min;
	let currentMaxValue = sliderMaxRangeInputEl.max;
	let valueChanged = false;
	const selectedFilter = getParameter(filterQs);
	let timeout: NodeJS.Timeout = undefined; // timeout to trigger selected value

	const deselect = (currentEl?: HTMLInputElement | null) => {
		if (showSliderEls.length) filterEl.classList.add("u-hide");

		showSliderEls.forEach(chk => {
			if (currentEl && currentEl.id !== chk.id) chk.checked = false;
			if (!currentEl) chk.checked = false;
		});
	};

	const drawSlider = (sliderRangeInputEl: HTMLInputElement, sliderRangeThumbEl: HTMLElement, sliderTextInputEl: HTMLInputElement, containerSliderTextInputEl: HTMLElement, outOfBoundsBarEl: HTMLElement, isLeftSliderMoved: boolean, isTextInputChanged: boolean): boolean => {
		const rangeMin = parseInt(sliderRangeInputEl.min);
		const rangeMax = parseInt(sliderRangeInputEl.max);

		if (isTextInputChanged) {
			// Value is empty => correct to currentValue
			if (!sliderTextInputEl.value) {
				if (isLeftSliderMoved)
					sliderTextInputEl.value = currentMinValue;
				else
					sliderTextInputEl.value = currentMaxValue;
				return false;
			} else {
				// Value hasn't changed return false to omit updating the page
				if (isLeftSliderMoved && sliderTextInputEl.value === currentMinValue || !isLeftSliderMoved && sliderTextInputEl.value === currentMaxValue) return false;

				let value = isLeftSliderMoved
					? parseInt(sliderTextInputEl.value) < parseInt(sliderMinRangeInputEl.min) ? parseInt(sliderMinRangeInputEl.min) : parseInt(sliderTextInputEl.value)
					: parseInt(sliderTextInputEl.value) > parseInt(sliderMaxRangeInputEl.max) ? parseInt(sliderMaxRangeInputEl.max) : parseInt(sliderTextInputEl.value);

				// !value triggers on 0 => we don't want this
				if (value !== 0 && !value)
					value = isLeftSliderMoved
						? parseInt(sliderMaxRangeInputEl.min)
						: parseInt(sliderMinRangeInputEl.max);

				// Numerical value => use it or fallback to max or min if bounds are exceeded
				sliderTextInputEl.value = isLeftSliderMoved
					? Math.min(value, parseInt(sliderMaxRangeInputEl.value)).toString()
					: Math.max(value, parseInt(sliderMinRangeInputEl.value)).toString();

				sliderRangeInputEl.value = sliderTextInputEl.value;
			}

			// All ok, save new values
			if (isLeftSliderMoved)
				currentMinValue = sliderTextInputEl.value;
			else
				currentMaxValue = sliderTextInputEl.value;

		} else {
			sliderRangeInputEl.value = isLeftSliderMoved
				? Math.min(parseInt(sliderRangeInputEl.value), parseInt(sliderMaxRangeInputEl.value)).toString()
				: Math.max(parseInt(sliderRangeInputEl.value), parseInt(sliderMinRangeInputEl.value)).toString();
			sliderTextInputEl.value = sliderRangeInputEl.value;
		}

		// set correct z index when inputs on max/min so user can slide again
		if ((!isLeftSliderMoved && parseInt(sliderTextInputEl.value) === rangeMax)
			|| (isLeftSliderMoved && parseInt(sliderTextInputEl.value) === rangeMin)) {
			const sliderCompletelyOnMax = !isLeftSliderMoved && parseInt(sliderTextInputEl.value) === rangeMax;

			sliderMaxRangeInputEl.style.zIndex = sliderCompletelyOnMax ? '1' : '2';
			sliderMinRangeInputEl.style.zIndex = sliderCompletelyOnMax ? '2' : '1';
		}

		const percent = (min, max) => Math.ceil(((parseInt(sliderRangeInputEl.value) - min) / (max - min)) * 100);
		const percentStyle = percent(rangeMin, rangeMax) + '%';
		const reversePercentStyle = 100 - percent(rangeMin, rangeMax) + '%';

		const inBoundsBarEl = expressQuerySelector<HTMLElement>(filterEl, ".a-form-input__slider-range", true);
		if (isLeftSliderMoved) {
			inBoundsBarEl.style.left = percentStyle;
			outOfBoundsBarEl.style.width = percentStyle;
		} else {
			inBoundsBarEl.style.right = reversePercentStyle;
			outOfBoundsBarEl.style.width = reversePercentStyle;
		}

		sliderRangeThumbEl.style.left = percentStyle;
		return true;
	};

	const onInput = (sliderTextInputEl: HTMLInputElement) => {
		if (!onlyNumber(sliderTextInputEl.value) || isNaN(parseInt(sliderTextInputEl.value))) {
			sliderTextInputEl.value = '';
		}
	};

	const isNoneInputSelected = () => {
		return showSliderEls.some(x => !x.classList.contains('technical-show-slider') && x.checked);
	};

	const hideSlider = (el: HTMLInputElement) => {
		if (!showSliderEls.length) return;

		if (showSliderEls.every(f => !f.checked))
			removeRangeFilter();
		else {
			const showSlider = el.classList.contains("technical-show-slider") && el.checked;

			if (showSlider) {
				const max = sliderMaxRangeInputEl.max;
				const min = sliderMinRangeInputEl.min;

				sliderMinTextInputEl.value = min;
				sliderMaxTextInputEl.value = max;
				sliderMinRangeInputEl.value = min;
				sliderMaxRangeInputEl.value = max;

				drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMaxBarEl, true, false);
				drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, false);

				addTextToFilterContainer(filtersContainerEl, TypeOfFilter.Slider, selectedFiltersContainerEl, filterCategory, min + " - " + max, removeRangeFilter);
				filterEl.classList.remove("u-hide");
				triggerValueClicked(true);
			} else {
				addTextToFilterContainer(filtersContainerEl, TypeOfFilter.Slider, selectedFiltersContainerEl, filterCategory, "0", removeRangeFilter);
				filterEl.classList.add("u-hide");
				triggerValueClicked(true, '0-0');
			}
		}
	};

	const addSliderFilter = () => {
		const isNoneSelected = isNoneInputSelected();

		const min = sliderMinTextInputEl.value;
		const max = sliderMaxTextInputEl.value;
		const value = isNoneSelected
			? '0'
			: min == max ? max : min + " - " + max;

		addTextToFilterContainer(filtersContainerEl, TypeOfFilter.Slider, selectedFiltersContainerEl, filterCategory, value, removeRangeFilter);
		triggerValueClicked(true, isNoneSelected ? '0' : min + '-' + max);
	};

	const triggerValueClicked = (update: boolean, valueOverride?: string) => {
		if (timeout) clearTimeout(timeout);
		timeout = onFilter && setTimeout(() => {
			onFilter(false, filterQs, valueOverride || (sliderMinTextInputEl.value + "-" + sliderMaxRangeInputEl.value), true, containerEl, update);
		}, 1000);
	};

	const removeRangeFilter = (update = true) => {
		deselect();

		sliderMinTextInputEl.value = originalMinValue;
		sliderMaxTextInputEl.value = originalMaxValue;
		sliderMinRangeInputEl.value = originalMinValue;
		sliderMaxRangeInputEl.value = originalMaxValue;

		drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMinBarEl, true, false);
		drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, false);

		onFilter(false, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxRangeInputEl.value, false, containerEl, update);
		updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinRangeInputEl.value + "-" + sliderMaxTextInputEl.value, true, filterEl.dataset.analytics);
		removeFromFilterContainer(filtersContainerEl, selectedFiltersContainerEl, filterCategory);
	};

	if (selectedFiltersContainerEl)
		attachRemoveFilterEvent(selectedFiltersContainerEl, filterCategory, removeRangeFilter);

	expressEventListener(sliderMinRangeInputEl, 'mousedown', () => {
		sliderMinRangeInputEl.style.zIndex = "2";
		sliderMaxRangeInputEl.style.zIndex = "1";
	});
	expressEventListener(sliderMaxRangeInputEl, 'mousedown', () => {
		sliderMaxRangeInputEl.style.zIndex = "2";
		sliderMinRangeInputEl.style.zIndex = "1";
	});

	// Change event is fired while moving the handle
	expressEventListener(sliderMinRangeInputEl, 'input', () => {
		valueChanged = drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMinBarEl, true, false);
	});
	expressEventListener(sliderMaxRangeInputEl, 'input', () => {
		valueChanged = drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, false);
	});

	// Change event is fired when you stop moving the handle
	expressEventListener(sliderMinRangeInputEl, 'change', () => {
		if (!valueChanged) return;
		addSliderFilter();
		updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
	});
	expressEventListener(sliderMaxRangeInputEl, 'change', () => {
		if (!valueChanged) return;
		addSliderFilter();
		updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
	});

	// Events for the input fields
	expressEventListener(sliderMinTextInputEl, 'input', () => onInput(sliderMinTextInputEl));
	expressEventListener(sliderMaxTextInputEl, 'input', () => onInput(sliderMaxTextInputEl));
	expressEventListener(sliderMinTextInputEl, 'focusout', () => {
		if (drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMinBarEl, true, true)) {
			addSliderFilter();
			updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
		}
	});
	expressEventListener(sliderMaxTextInputEl, 'focusout', () => {
		if (drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, true)) {
			addSliderFilter();
			updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
		}
	});
	expressEventListener(sliderMinTextInputEl, 'keydown', (ev: KeyboardEvent) => {
		if (ev.which === 13 || ev.keyCode === 13) {
			if (drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMinBarEl, true, true)) {
				addSliderFilter();
				updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
			}
		}
	});
	expressEventListener(sliderMaxTextInputEl, 'keydown', (ev: KeyboardEvent) => {
		if (ev.which === 13 || ev.keyCode === 13) {
			if (drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, true)) {
				addSliderFilter();
				updateParametersForUrl(TypeOfFilter.Slider, filterQs, sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, false, filterEl.dataset.analytics);
			}
		}
	});

	showSliderEls.forEach(el => expressEventListener(el, 'click', () => {
		hideSlider(el);
		updateParametersForUrl(TypeOfFilter.Slider, filterQs, !el.classList.contains('technical-show-slider') ? "0" : sliderMinTextInputEl.value + "-" + sliderMaxTextInputEl.value, (el.classList.contains('technical-show-slider') && !el.checked), filterEl.dataset.analytics);
	}));

	if (selectedFilter && collapsableComponent) {
		const values = selectedFilter.split('-');
		const min = values[0];
		const max = values[1] || values[0];

		const originalMinValue = sliderMinTextInputEl.value;
		const originalMaxValue = sliderMaxTextInputEl.value;

		sliderMinTextInputEl.value = min;
		sliderMaxTextInputEl.value = max;
		sliderMinRangeInputEl.value = min;
		sliderMaxRangeInputEl.value = max;

		if (min == max && (max == sliderMinRangeInputEl.min || min == sliderMaxRangeInputEl.max)) {
			const sliderCompletelyOnMax = min == sliderMaxRangeInputEl.max;

			sliderMaxRangeInputEl.style.zIndex = sliderCompletelyOnMax ? '1' : '2';
			sliderMinRangeInputEl.style.zIndex = sliderCompletelyOnMax ? '2' : '1';
		}
		const isNoneSelected = isNoneInputSelected();
		drawSlider(sliderMinRangeInputEl, sliderMinRangeThumbEl, sliderMinTextInputEl, containerMinSliderTextInputEl, outOfBoundsMaxBarEl, true, false);
		drawSlider(sliderMaxRangeInputEl, sliderMaxRangeThumbEl, sliderMaxTextInputEl, containerMaxSliderTextInputEl, outOfBoundsMaxBarEl, false, false);
		addSliderFilter();
		triggerValueClicked(!isNoneSelected && (originalMaxValue !== max || originalMinValue !== min), isNoneSelected ? '0' : null);
		toggleResetFilters(filtersContainerEl, false);
		collapsableComponent.open();
	}

	return {
		removeRangeFilter
	};
}
