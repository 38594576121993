import {expressEventListener, expressQuerySelector, expressQuerySelectorAll} from "../common/html";
import {trackFiltersEvent} from "../common/events";
import {getParameter, queryRemoveKeys, updateParameter} from "../utils/querystring";
import {findInsertIndex} from "../utils/dom-tree";
import {initShowMoreElements} from "../components/show-more-elements";
import {createCollapsable} from "../components/collapsable";
import {createCheckBoxSelectFilter} from "../components/filters/checkboxFilter";
import {createColorFilter} from "../components/filters/colorFilter";
import {createRangeFilter, IRangeFilter} from "../components/filters/rangeFilter";

export enum TypeOfFilter { Regular = 0,	MultiSelect = 1, Icons = 2, Slider = 3 }

export enum TemplateType { Text = 0, Icon = 1 }

export interface IFilters {
	readonly resetFiltersContainerEl: () => void;
}

export interface ICreateFilterOptions {
	readonly onFilter?: (multiselect: boolean, filterName: string, filterValue: string, checked: boolean, containerEl: HTMLElement, updateValue?: boolean) => void;
	readonly resetAll?: (containerEl: HTMLElement) => void;
}

export interface IFilterDependencies {
	readonly onFilter?: (multiselect: boolean, filterName: string, filterValue: string, checked: boolean, containerEl: HTMLElement, updateValue?: boolean) => void;
	readonly addFilterTemplate?: (templateType: TemplateType, filterCategory: string, selectedFilterName: string, htmlOrColor: string, removeFilterEvent: any) => HTMLDivElement;
	readonly attachRemoveFilterEvent?: (selectedFiltersContainerEl: HTMLElement, filterForCategory: string, removeEvent: any, filterForCategoryValue?: string) => void;
	readonly removeFromFilterContainer?: (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement, filterCategory: string, filterCategoryValue?: string) => void;
	readonly showSelectedFilters?: (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement) => void;
	readonly toggleResetFilters?: (filtersContainerEl: HTMLElement, force?: boolean) => void;
	readonly updateParametersForUrl?: (typeOfFilter: TypeOfFilter, filterQs: string, msg: string, removeFromUrl: boolean, analytics?: string, possibleValues?: string[]) => void;
	readonly addTextToFilterContainer?: (filtersContainerEl: HTMLElement, typeOfFilter: TypeOfFilter, selectedFiltersContainerEl: HTMLElement, filterCategory: string, selectedFilterValue: string, removeFilterEvent: any, selectedFilterName?: string) => void;
}

export function createFilters(containerEl: HTMLElement, opts: ICreateFilterOptions): IFilters {
	const selectedFiltersContainerEl = expressQuerySelector<HTMLElement>(containerEl, '.technical-selectedfilters', false);
	const noneSelectedLabelEl = selectedFiltersContainerEl && expressQuerySelector(selectedFiltersContainerEl, '.technical-no-selected-filters', false);
	const { onFilter, resetAll } = opts;

	const addFilterTemplate = (templateType: TemplateType, filterCategory: string, selectedFilterName: string, htmlOrColor: string, removeFilterEvent: any): HTMLDivElement => {
		const div = document.createElement('div');
		div.dataset.filterforcategory = filterCategory;
		div.classList.add("a-filter-tag", templateType === TemplateType.Text ? "a-filter-tag--text" : "a-filter-tag--color");
		div.dataset.filterforcategoryvalue = selectedFilterName;

		if (templateType === TemplateType.Text) {
			const p = document.createElement('p');
			p.classList.add("a-filter-tag__label");
			const content = document.createTextNode(htmlOrColor);
			p.appendChild(content);
			div.appendChild(p);
		}
		else {
			const iconSpan = document.createElement('span');
			iconSpan.classList.add("a-icon", "a-icon--color", "a-icon--unselected", "a-icon--small", "a-icon--no-hover", "a-icon--" + htmlOrColor);
			iconSpan.style.color = htmlOrColor;
			if (htmlOrColor === "white" || htmlOrColor === "custom-nocolor") iconSpan.classList.add("a-icon--outline");
			const icon = document.createElement('i');
			icon.classList.add("a-icon__icon");
			const iconColorSpan = document.createElement('span');
			iconColorSpan.classList.add("a-icon__color");
			icon.appendChild(iconColorSpan);
			iconSpan.appendChild(icon);
			div.appendChild(iconSpan);
		}

		const span = document.createElement('span');
		span.dataset.eventalreadybinded = "true";
		span.classList.add("a-filter-tag__remove", "icon-close");
		span.addEventListener("click", removeFilterEvent);
		div.appendChild(span);

		return div;
	};

	const removeFromFilterContainer = (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement, filterCategory: string, filterCategoryValue?: string) => {
		const selector = !filterCategoryValue ? `[data-filterforcategory="${filterCategory}"]` : `[data-filterforcategory="${filterCategory}"][data-filterforcategoryvalue="${filterCategoryValue}"]`;
		const selectedFilterCategoryEl = expressQuerySelector<HTMLElement>(selectedFiltersContainerEl, selector, false);
		if (selectedFilterCategoryEl) selectedFilterCategoryEl.remove();

		hideSelectedFilters(filtersContainerEl, selectedFiltersContainerEl);
	};

	const addTextToFilterContainer = (filtersContainerEl: HTMLElement, typeOfFilter: TypeOfFilter, selectedFiltersContainerEl: HTMLElement, filterCategory: string, selectedFilterValue: string, removeFilterEvent: any, selectedFilterName?: string) => {
		showSelectedFilters(filtersContainerEl, selectedFiltersContainerEl);

		if (!selectedFiltersContainerEl)
			return;

		const selector = typeOfFilter === TypeOfFilter.Regular || typeOfFilter === TypeOfFilter.Slider ? `[data-filterforcategory="${filterCategory}"]` : `[data-filterforcategory="${filterCategory}"][data-filterforcategoryvalue="${selectedFilterValue}"]`;
		const selectedFilterCategoryEl = expressQuerySelector<HTMLElement>(selectedFiltersContainerEl, selector, false);
		const selectedFilterDisplayName = selectedFilterName || selectedFilterValue;
		if (selectedFilterCategoryEl) {
			const filterCategoryContentEl = expressQuerySelector<HTMLElement>(selectedFilterCategoryEl, 'p.a-filter-tag__label', true);
			filterCategoryContentEl.innerHTML = typeOfFilter === TypeOfFilter.Slider ? filterCategory + ": " + selectedFilterDisplayName : selectedFilterDisplayName;
			return;
		}

		const filter = addFilterTemplate(TemplateType.Text, filterCategory, selectedFilterValue, typeOfFilter === TypeOfFilter.Slider ? filterCategory + ": " + selectedFilterDisplayName : selectedFilterDisplayName, removeFilterEvent);
		const allSelectedColorFilters = expressQuerySelectorAll<HTMLElement>(selectedFiltersContainerEl, `[data-filterforcategory="${filterCategory}"]`);
		if (allSelectedColorFilters.length) {
			allSelectedColorFilters[allSelectedColorFilters.length - 1].insertAdjacentElement('afterend', filter);
			return;
		}

		const filters = expressQuerySelectorAll(document, '[data-typeoffilter][data-filtercategory]') as HTMLElement[];
		const compare = (val1: HTMLElement) => filters.findIndex(e => val1.dataset.filterforcategory === e.dataset.filtercategory);
		const selectedFiltersChildren = expressQuerySelectorAll<HTMLElement>(selectedFiltersContainerEl, '[data-filterforcategory]');
		const index = findInsertIndex(selectedFiltersChildren, filter, compare);
		const elementToInsertBefore = selectedFiltersChildren[index];
		if (!elementToInsertBefore || index <= -1 || index > selectedFiltersChildren.length) {
			selectedFiltersContainerEl.appendChild(filter);
			return;
		}
		elementToInsertBefore.insertAdjacentElement('beforebegin', filter);
	};

	const attachRemoveFilterEvent = (selectedFiltersContainerEl: HTMLElement, filterForCategory: string, removeEvent: any, filterForCategoryValue?: string) => {
		const selectedFilterCategoryEl = expressQuerySelector<HTMLElement>(selectedFiltersContainerEl, !filterForCategoryValue ? `[data-filterforcategory="${filterForCategory}"]` : `[data-filterforcategory="${filterForCategory}"][data-filterforcategoryvalue="${filterForCategoryValue}"]`, false);

		if (selectedFilterCategoryEl) {
			const removeEl = expressQuerySelector<HTMLElement>(selectedFilterCategoryEl, '.a-filter-tag__remove', false);
			if (removeEl && (!removeEl.dataset.eventalreadybinded || removeEl.dataset.eventalreadybinded !== "true")) {
				removeEl.dataset.eventalreadybinded = "true";
				removeEl.addEventListener("click", removeEvent);
			}
		}
	};

	const hideSelectedFilters = (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement) => {
		const resetFilterEls = expressQuerySelectorAll<HTMLElement>(filtersContainerEl, '.o-filters__button-reset');

		if (!selectedFiltersContainerEl) {
			resetFilterEls.forEach(resetFilterEl => resetFilterEl.classList.add("a-button--disabled"));
			return;
		}

		const filterContainerTagEls = expressQuerySelectorAll(selectedFiltersContainerEl.parentElement, '.a-filter-tag');

		if (!filterContainerTagEls.length && !selectedFiltersContainerEl.classList.contains("o-filters__selected-filters--none")) {
			selectedFiltersContainerEl.classList.add("o-filters__selected-filters--none");
			noneSelectedLabelEl && noneSelectedLabelEl.classList.remove('u-hide');
			resetFilterEls.forEach(resetFilterEl => resetFilterEl.classList.add("a-button--disabled"));
		}
	};

	const toggleResetFilters = (filtersContainerEl: HTMLElement, force?: boolean) => {
		const resetFilterEls = expressQuerySelectorAll<HTMLElement>(filtersContainerEl, '.o-filters__button-reset');
		resetFilterEls.forEach(resetFilterEl => resetFilterEl.classList.toggle("a-button--disabled", force));
	};

	const showSelectedFilters = (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement) => {
		toggleResetFilters(filtersContainerEl, false);

		if (!selectedFiltersContainerEl)
			return;

		if (selectedFiltersContainerEl.classList.contains("o-filters__selected-filters--none")) {
			selectedFiltersContainerEl.classList.remove("o-filters__selected-filters--none");
			noneSelectedLabelEl && noneSelectedLabelEl.classList.add('u-hide');
		}
	};

	const updateParametersForUrl = (typeOfFilter: TypeOfFilter, filterQs: string, msg: string, removeFromUrl: boolean, analytics?: string, possibleValues?: string[]) => {
		trackFiltersEvent('FilterClicked', { filter: analytics ? analytics : `${filterQs}_${msg}` });

		if (!filterQs)
			return;

		let url: string;
		const value = encodeURIComponent(msg.toLowerCase());
		if (typeOfFilter === TypeOfFilter.Regular || typeOfFilter === TypeOfFilter.Slider) {
			url = removeFromUrl ? updateParameter(filterQs.toLowerCase(), '') : updateParameter(filterQs.toLowerCase(), value);
		} else {
			const param = getParameter(filterQs.toLowerCase());
			let params = param ? param.split(",") : [];
			if (params.indexOf(value) < 0)
				params.push(value);
			else if (removeFromUrl)
				params = params.filter(f => f !== value);

			if (possibleValues) {
				params = params.filter(x => possibleValues.includes(x));
			}

			url = updateParameter(filterQs.toLowerCase(), params.join(","));
		}

		window.history.pushState({}, null, url.toLowerCase());
	};

	hideSelectedFilters(containerEl, selectedFiltersContainerEl);

	const collapsibleEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.m-collapsable');
	const filterQsArr: string[] = [];
	const rangeFilterComponents: IRangeFilter[] = [];
	collapsibleEls.forEach(el => {
		if (el.classList.contains('technical-selectedfilters')) return; // skip the selected filters collapsible

		const filterCategory = el.dataset.filtercategory;
		const elFilterQs = el.dataset.filterqs;

		const filterCollapsable = createCollapsable(el, { titleElSelector: '.m-collapsable__title', scrollIntoViewAfterOpen: false, openClass: 'is-collapse-open' });
		const showMore = expressQuerySelector<HTMLElement>(el, '.technical-showmore', false);
		filterQsArr.push(elFilterQs);

		if (showMore) {
			filterQsArr.push(elFilterQs ? `showmore${elFilterQs.toLowerCase()}` : null);
			initShowMoreElements(el, showMore, elFilterQs ? `showmore${elFilterQs.toLowerCase()}` : null, '.technical-filter-value');
		}

		const filterCheckboxEls = expressQuerySelectorAll<HTMLElement>(el, '.a-form-input--checkbox, .a-form-input--radio');
		const filterCheckboxPossibleValues = filterCheckboxEls.map(x => [encodeURIComponent(x.dataset.filterelement)?.toLowerCase(), encodeURIComponent(x.dataset.filterkey)?.toLowerCase()]).flat();
		filterCheckboxEls.forEach(filterEl => {
			if (TypeOfFilter[el.dataset.typeoffilter] === TypeOfFilter.Regular || TypeOfFilter[el.dataset.typeoffilter] === TypeOfFilter.MultiSelect)
				createCheckBoxSelectFilter(filterCollapsable, TypeOfFilter[el.dataset.typeoffilter], selectedFiltersContainerEl, elFilterQs, filterCategory, filterEl, filterCheckboxEls, filterCheckboxPossibleValues, containerEl, {updateParametersForUrl, removeFromFilterContainer, onFilter, attachRemoveFilterEvent, toggleResetFilters, addTextToFilterContainer});
		});

		const colorEls = expressQuerySelectorAll<HTMLElement>(el, '.a-icon--color');
		const colorElsPossibleValues = colorEls.map(x => encodeURIComponent(x.dataset.filterelement.toLowerCase()));
		colorEls.forEach(filterEl => {
			createColorFilter(filterCollapsable, selectedFiltersContainerEl, elFilterQs, filterCategory, filterEl, containerEl, colorElsPossibleValues, { showSelectedFilters, addFilterTemplate, updateParametersForUrl, removeFromFilterContainer, onFilter, attachRemoveFilterEvent, toggleResetFilters });
		});

		const sliderEls = expressQuerySelectorAll<HTMLElement>(el, '.a-form-input--slider');
		sliderEls.forEach(filterEl => {
			const showSliderEls = expressQuerySelectorAll<HTMLInputElement>(el, 'input[type=checkbox],input[type=radio]');
			const rangeFilterComponent = createRangeFilter(filterCollapsable, selectedFiltersContainerEl, elFilterQs, filterCategory, filterEl, showSliderEls, containerEl, { updateParametersForUrl, removeFromFilterContainer, onFilter, attachRemoveFilterEvent, toggleResetFilters, addTextToFilterContainer });

			rangeFilterComponents.push(rangeFilterComponent);
		});
	});

	const resetFilters = expressQuerySelectorAll<HTMLElement>(containerEl.parentElement, '.technical-reset-filters');
	const resetFiltersContainerEl = () => {
		trackFiltersEvent('FilterReset');
		if (resetAll)
			resetAll(containerEl);

		collapsibleEls.forEach(collapsibleEl => { if (collapsibleEl.classList.contains('is-collapse-open') && !collapsibleEl.classList.contains('m-collapsable--sorting-top')) collapsibleEl.classList.remove('is-collapse-open'); });

		const colorEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.a-icon--color');
		colorEls.forEach(colorEl => {
			if (colorEl.classList.contains('a-icon--selected')) {
				colorEl.classList.remove('a-icon--selected');
				colorEl.classList.add('a-icon--unselected');
			}
		});

		const filterEls = expressQuerySelectorAll<HTMLInputElement>(containerEl, '.technical-filter');
		filterEls.forEach(filterEl => {
			const checkboxElsPerFilter = expressQuerySelectorAll<HTMLInputElement>(filterEl, 'input[type=checkbox]:not(.technical-sorting-radio),input[type=radio]:not(.technical-sorting-radio)');
			let batchSizeDesktop = -1;
			let batchSizeMobile = -1;

			checkboxElsPerFilter.forEach((checkboxEl, indx) => {
				const showMoreEl = expressQuerySelector<HTMLElement>(checkboxEl.closest(".m-collapsable__content"), ".technical-no-hide-parrent", false);

				if (showMoreEl) {
					batchSizeDesktop = showMoreEl.dataset.batchsizeDesktop ? +showMoreEl.dataset.batchsizeDesktop : batchSizeDesktop;
					batchSizeMobile = showMoreEl.dataset.batchsizeMobile ? +showMoreEl.dataset.batchsizeMobile : batchSizeMobile;

					if (indx > batchSizeDesktop - 1) {
						if (showMoreEl.classList.contains("u-hide-on-desktop-full")) showMoreEl.classList.remove("u-hide-on-desktop-full");
						checkboxEl.closest(".technical-filter-value").classList.add("u-hide-on-desktop-full");
					}

					if (indx > batchSizeMobile - 1) {
						if (showMoreEl.classList.contains("u-hide-on-mobile-full")) showMoreEl.classList.remove("u-hide-on-mobile-full");
						checkboxEl.closest(".technical-filter-value").classList.add("u-hide-on-mobile-full");
					}
				}

				checkboxEl.checked = false;
			});
		});

		rangeFilterComponents.forEach(showSliderEl => showSliderEl.removeRangeFilter(false));

		const filterTagEls = expressQuerySelectorAll<HTMLElement>(selectedFiltersContainerEl, '.a-filter-tag');
		filterTagEls.forEach(el => el.remove());

		hideSelectedFilters(containerEl, selectedFiltersContainerEl);
		window.history.pushState({}, null, queryRemoveKeys(filterQsArr));
	};
	resetFilters.forEach(resetFilter => expressEventListener(resetFilter, "click", _ => !resetFilter.classList.contains('a-button--disabled') && resetFiltersContainerEl()));
	const filterEl = expressQuerySelector(containerEl, '.technical-filters-container', false) || containerEl;
	filterEl.classList.remove('skeleton-loading');

	return {
		resetFiltersContainerEl
	} as IFilters;
}
