﻿import {
	expressAddClass,
	expressEventListener,
	expressQuerySelector,
	expressRemoveClass,
	expressToggleClass, getParents,
	isElementVisible
} from "../common/html";
import {IAutocompleteItem, autocomplete} from "../external/autocomplete";
import {compositeDisposable, IDisposable} from "../utils/disposable";
import { postRequest } from "../utils/fetch";

export interface IBaseFormInput {
	readonly enable: () => void;
	readonly disable: () => void;
	readonly inputEl: HTMLInputElement | HTMLSelectElement;
	readonly clear: (clearValue?: boolean) => void;
	readonly setError: (error: string, clear?: boolean) => void;
	readonly hide: () => void;
	readonly show: () => void;
}

export interface IFormInput extends IBaseFormInput {
	readonly inputEl: HTMLInputElement;
	readonly setValue: (value: string) => void;
	readonly getValue: () => string | boolean;
}

export interface ISelectBox extends IBaseFormInput {
	readonly inputEl: HTMLSelectElement;
	readonly dispose: () => void;
	readonly setValue: (value: string) => void;
	readonly getValue: () => string;
}

export function createBaseFormInput(containerEl: HTMLElement, altSelector?: string): IBaseFormInput {
	const inputEl = expressQuerySelector<HTMLInputElement | HTMLSelectElement>(containerEl, altSelector || ".a-form-input__input", true);
	const errorParagraphEl = expressQuerySelector<HTMLElement>(containerEl, '.a-form-input__error-text');

	const toggleAttribute = (attr: string, force: boolean) => inputEl.toggleAttribute(attr, force);

	const toggleEnable = (enable: boolean) => {
		toggleAttribute('disabled', !enable);
		expressToggleClass(containerEl, 'a-form-input--disabled', !enable);
	};

	const clear = (clearValue = false) => {
		clearValue && (inputEl.value = '');
		containerEl.classList.remove("a-form-input--validated");
		containerEl.classList.remove("a-form-input--error");
		errorParagraphEl && (errorParagraphEl.innerHTML = '');
	};

	const setError = (error: string, doClear = false) => {
		doClear && clear();
		containerEl.classList.remove("a-form-input--validated");
		containerEl.classList.add("a-form-input--error");

		errorParagraphEl && (errorParagraphEl.innerHTML = error);
	};

	inputEl && inputEl.addEventListener('focusin', () => clear(false));

	return {
		clear,
		setError,
		enable: () => toggleEnable(true),
		disable: () => toggleEnable(false),
		inputEl,
		show: () => containerEl.classList.remove('u-hide'),
		hide: () => containerEl.classList.add('u-hide')
	};
}

export function createCheckBox(containerEl: HTMLElement, onChange?: (checked: boolean) => void, altSelector?: string) {
	const input = createFormInput(containerEl, altSelector);
	let event: IDisposable;
	onChange && (event = expressEventListener(input.inputEl, "change", () => onChange(!!input.getValue())));

	return {
		...input,
		dispose: () => event && event.dispose
	};
}

export function createSelectBox(containerEl: HTMLElement, onSelected?: (value?: string) => void) {
	const baseComponent = createBaseFormInput(containerEl);
	const selectEl = baseComponent.inputEl as HTMLSelectElement;

	const setValue = (value: string) => (selectEl.value = value);
	const onSelect = () => onSelected && onSelected(selectEl.value);

	const disposableEvent = expressEventListener(selectEl, 'change', onSelect);
	return {
		...baseComponent,
		dispose: disposableEvent.dispose,
		inputEl: selectEl,
		setValue,
		getValue: () => selectEl.value
	} as ISelectBox;
}

export function createFormInput(containerEl: HTMLElement, altSelector?: string): IFormInput {
	const baseComponent = createBaseFormInput(containerEl, altSelector);
	const inputEl = baseComponent.inputEl as HTMLInputElement;
	const isCheckBox = inputEl.type.toLowerCase() === 'checkbox';

	const setValue = (value: string | boolean) =>
		isCheckBox
			? inputEl.checked = value.toString().toLowerCase() === "true"
			: (inputEl.value = value.toString());

	const getValue = () =>
		isCheckBox
			? inputEl.checked
			: inputEl.value;
	return {
		setValue,
		getValue,
		...baseComponent,
		inputEl
	};
}

export function createPasswordFormInput(containerEl: HTMLElement): IFormInput {
	const formInput = createFormInput(containerEl);
	const iconEl = expressQuerySelector<HTMLElement>(containerEl, ".technical-password-unhide-icon", false);
	let disposableEvent: IDisposable | null;

	expressEventListener(formInput.inputEl, 'blur', (ev) => {
		const value = formInput.inputEl.value;

		formInput.inputEl.dataset['lastvalidatedpassword'] = null;
		formInput.inputEl.dataset['lastvalidatedresult'] = null;
		if (value) {
			postRequest('/ajaxxhr/Login/ValidatePassword',
				{
					password: btoa(formInput.inputEl.value)
				},
				{ headers: { 'Content-type': 'application/json; charset=utf-8' } }).then(res => {
					formInput.inputEl.dataset['lastvalidatedpassword'] = value;
					formInput.inputEl.dataset['lastvalidatedresult'] = res as string;
				});
		}
	});

	const onPasswordShowHideIconClick = () => {
		switch (formInput.inputEl.getAttribute("type")) {
			case "password":
				formInput.inputEl.setAttribute("type", "text");
				if (iconEl) iconEl.classList.add("a-form-input__password-unhide-icon--hide");
				break;
			case "text":
				formInput.inputEl.setAttribute("type", "password");
				if (iconEl) iconEl.classList.remove("a-form-input__password-unhide-icon--hide");
				break;
			default:
				break;
		}
	};

	const disable = () => {
		formInput.disable();
		if (iconEl) {
			iconEl.style.cursor = 'not-allowed';
			dispose();
		}
	};

	const enable = () => {
		formInput.enable();
		if (iconEl) {
			iconEl.style.cursor = 'pointer';
			addEvent();
		}
	};

	const dispose = () => {
		if (disposableEvent) disposableEvent.dispose();
	};
	const addEvent = () => (disposableEvent = iconEl ? expressEventListener(iconEl, 'click', onPasswordShowHideIconClick) : null);

	if (iconEl) addEvent();

	return {
		...formInput,
		disable,
		enable,
	};
}

interface IAutoCompleteItem extends IAutocompleteItem {
	readonly value: string;
	readonly label: string;
}

export interface ICreateTextInputWithAutoCompleteDependencies {
	readonly autoCompleteSearch: (searchTerm: string) => Promise<{ value: string, label: string }[]>;
	readonly onSelect?: (item: { value: string, label: string }) => void;
	readonly customize?: (input: HTMLInputElement, inputRect: ClientRect | DOMRect, container: HTMLDivElement, maxHeight: number) => void;
	readonly onOpen?: () => void,
	readonly onClose?: () => void,
	readonly renderTextContent?: (item: { value: string, label: string }, currentSearchterm: string) => string;
	readonly debounceWaitMs?: number;
	readonly containerInsertPosition?: InsertPosition;
	readonly containerClass?: string;
	readonly disableAutoSelect?: boolean;
	readonly preventSubmit?: boolean;
	readonly minLength?: number;
}

export function createTextInputWithAutoComplete(inputEl: HTMLInputElement, deps: ICreateTextInputWithAutoCompleteDependencies) {
	const onCloseDisposable = compositeDisposable([]);
	inputEl.setAttribute('autocomplete', 'off');
	// defaults are for choice of delivery
	const {
		autoCompleteSearch,
		onSelect,
		customize,
		onOpen,
		onClose,
		renderTextContent,
		debounceWaitMs = 250,
		containerInsertPosition = 'beforeend',
		containerClass = 'm-autocomplete a-form-input__autocomplete',
		disableAutoSelect = true,
		preventSubmit = false,
		minLength = 3
	} = deps;

	const onMouseDownEvent = (ev: MouseEvent, container: HTMLDivElement) => {
		ev.stopPropagation();
		if (getParents(ev.target, ".technical-search-suggestions").length < 1) onClose();
		container.remove();
		onCloseDisposable.dispose();
	};

	const autocompleteResult = autocomplete<IAutoCompleteItem>({
		minLength,
		input: inputEl,
		disableAutoSelect,
		preventSubmit,
		onSelect: (item: IAutoCompleteItem, input) => {
			input.value = item.label || item.value;
			onSelect && onSelect({ value: item.value, label: item.label });
		},
		fetch: (text: string, update: (items: IAutoCompleteItem[]) => void) => {
			text = text.toLowerCase();
			autoCompleteSearch(text)
				.then(res => update(res as IAutoCompleteItem[]));
		},
		render: (item: IAutoCompleteItem, currentValue: string) => {
			const itemEl = document.createElement("div");
			expressAddClass(itemEl, 'm-autocomplete__item autocomplete-suggestion technical-search-suggestions');
			itemEl.innerHTML = renderTextContent && renderTextContent(item, currentValue) || `${item.label} - ${item.value}`;

			return itemEl;
		},
		customize: (input: HTMLInputElement, inputRect: ClientRect | DOMRect, container: HTMLDivElement, maxHeight: number): void => {
			customize && customize(input, inputRect, container, maxHeight);
			expressRemoveClass(container, 'autocomplete');
			expressAddClass(container, containerClass);
			container.removeAttribute('style');
			container.style.display = "block";
			const inputContainerEl = input.parentElement!;
			inputContainerEl.insertAdjacentElement(containerInsertPosition, container);
			onOpen && onOpen();
			if (onClose) {
				getParents(container).forEach(el => {
					onCloseDisposable.add(expressEventListener(el, "mousedown", (ev) => {onMouseDownEvent(ev, container);} ));
				});
			}
		},
		debounceWaitMs
	});

	const disposable = onClose
		? compositeDisposable([expressEventListener(inputEl, 'input', _ => { if (inputEl.value && inputEl.value.length < minLength) onClose(); })])
	: null;

	return {
		dispose: () => { disposable && disposable.dispose(); onCloseDisposable && onCloseDisposable.dispose; autocompleteResult.destroy();},
	};
}
