﻿import {expressEventListener, hide, expressQuerySelector, expressQuerySelectorAll, remove, show} from "../common/html";
import {createClickableElement} from "./button";
import {compositeDisposable, IDisposable} from "../utils/disposable";
import {trackOrderStatusEvent} from "../common/events";

export interface ICreateNotificationCenterDependencies {
	readonly getNotifications: () => Promise<string>;
	readonly isMobile: () => boolean;
	readonly markNotificationsAsRead: (ids: string[]) => Promise<boolean>;
	readonly onOpen?: () => void;
	readonly onClose?: () => void;
}

export function createNotificationTile(containerEl: HTMLElement, markNotificationAsRead: (id: string) => Promise<void>) {
	const checkBoxEl = expressQuerySelector<HTMLInputElement>(containerEl, '.m-notification-tile__read-status', true);
	const linkEl = expressQuerySelector<HTMLAnchorElement>(containerEl, '.m-notification-tile__title-with-link__link', false);
	const onRemove = () => remove(containerEl);

	const onTileClick = (e: Event) => {
		e.preventDefault();
		const cb = expressQuerySelector<HTMLInputElement>(checkBoxEl, '.a-form-input__input', true);
		cb.disabled = true;
		checkBoxEl.removeEventListener('click', onTileClick);
		markNotificationAsRead(cb.value)
			.then(() => containerEl.classList.add("o-notifications-center__notification-tile--removed"))
			.then(() => onRemove());
	};

	checkBoxEl.addEventListener('click', onTileClick);
	linkEl && linkEl.addEventListener('click', () => {
		const orderNumber = linkEl.dataset.ordernumber || '';
		const pvc = linkEl.dataset.sku || '';
		const orderStatus = linkEl.dataset.orderstatus || '';
		trackOrderStatusEvent("ClickOnOrderItemNotificationLink", { orderNumber: orderNumber, pvc: pvc, orderStatus: orderStatus });
	});
}

export function createNotificationCenter(containerEl: HTMLElement, deps: ICreateNotificationCenterDependencies) {
	let onDocumentClickDisposable: IDisposable;
	let onMarkedAllAsReadDisposable: IDisposable;
	const domParser = new DOMParser();

	let loaded = false;

	const { getNotifications, isMobile, markNotificationsAsRead, onClose, onOpen } = deps;

	const buttonEl = expressQuerySelector<HTMLElement>(containerEl, '.o-icon-navigation__notifications-center__icon', true);
	const notificationBellCountEl = expressQuerySelector<HTMLSpanElement>(buttonEl, '.o-icon-navigation__count ', true);
	const menuEl = expressQuerySelector(containerEl, '.o-icon-navigation__notifications-center-menu', true);
	const overLayEl = expressQuerySelector<HTMLElement>(menuEl, '.technical-notification-center-overlay', true);
	const contentEl = expressQuerySelector<HTMLElement>(overLayEl, '.m-navigation-overlay__content', true);
	const closeButtonEl = expressQuerySelector(overLayEl, '.m-navigation-overlay__close-container', true);
	let markAllAsReadButton: HTMLInputElement;

	const setOverLayClass = () => {
		const mobile = isMobile();
		overLayEl.classList.remove(...["m-navigation-overlay__mobile-view", "m-navigation-overlay__desktop-view"]);
		overLayEl.classList.add(mobile ? "m-navigation-overlay__mobile-view" : "m-navigation-overlay__desktop-view");
	};

	const setNotificationCount = (amount: number, markAllAsReadButton?: HTMLElement) => {

		if (amount > 0) {
			notificationBellCountEl.innerHTML = amount.toString();
			if (markAllAsReadButton) markAllAsReadButton.innerHTML += `(${amount})`;
			show(notificationBellCountEl);

		} else {
			notificationBellCountEl.innerHTML = "";
			hide(notificationBellCountEl);
		}
	};

	const getTiles = () => expressQuerySelectorAll<HTMLElement>(contentEl, '.m-notification-tile');

	const onMarkedAsReadSingle = (ids: string) => markNotificationsAsRead(ids.split(';'))
		.then(() => loadNotifications(true));

	const onMarkedAllAsRead = () => {
		if (markAllAsReadButton) {
			markAllAsReadButton.disabled = true;
			markAllAsReadButton.classList.add("a-button--disabled");
		}
		detachMarkAllAsReadClick();
		const ids = getTiles()
			.map(el => expressQuerySelector<HTMLInputElement>
				(el, '.a-form-input__input', true)
					.value.split(";")
			)
			.reduce((a, b) => a.concat(b), []);

		// only execute if there are items to remove
		if (ids && ids.length > 0) {
			return markNotificationsAsRead(ids)
				.then(res => {
					if (res) loadNotifications(true);
				});
		}
	};

	const loadNotifications = (reload = false) => {

		if (loaded && !reload) return Promise.resolve();

		return getNotifications()
			.then(res => {
				if (res) {
					const parsed = domParser.parseFromString(res, "text/html").documentElement;
					const notificationCenterEl = expressQuerySelector(parsed, ".o-notifications-center__container", true);
					contentEl.innerHTML = "";
					contentEl.insertAdjacentElement('beforeend', notificationCenterEl);

					markAllAsReadButton = expressQuerySelector<HTMLInputElement>(contentEl, '.o-notifications-center__mark-as-read', true);

					const tiles = getTiles();
					if (tiles.length > 0) {
						markAllAsReadButton.classList.remove("a-button--disabled");

						tiles.forEach(el => createNotificationTile(el, onMarkedAsReadSingle));

						attachMarkAllAsReadClick(markAllAsReadButton);
					}

					if (tiles.length === 0) {
						markAllAsReadButton.classList.add("a-button--disabled");
					}

					setNotificationCount(tiles.length);

					loaded = true;
				}
			});
	};

	const onNotificationCenterOpen = () => {
		setOverLayClass();
		return loadNotifications();
	};

	const closeMenu = () => {
		menuEl.classList.remove("menu-is-open");
		detachDocumentClick();
		onClose && onClose();
		return Promise.resolve();
	};

	const openMenu = () => {
		trackOrderStatusEvent("ClickOnNotificationIcon", {});
		return onNotificationCenterOpen()
			.then(() => {
				onOpen && onOpen();
				attachOnDocumentClick();
				menuEl.classList.add("menu-is-open");
			});
	};

	const onCloseIconClick = (e: Event) => {
		e.stopPropagation();
		closeMenu();
	};

	const onButtonClick = () => {
		return menuEl.classList.contains("menu-is-open")
			? closeMenu()
			: openMenu();
	};

	const onDocumentClick = (e: MouseEvent) => {
		if (!(e.target as HTMLElement).closest(".o-icon-navigation__notifications-center-menu, .o-icon-navigation__notifications-center__icon")) {
			closeMenu();
		}
	};

	const attachOnDocumentClick = () =>
		(onDocumentClickDisposable = expressEventListener(document, 'click', onDocumentClick, true));

	const detachDocumentClick = () => onDocumentClickDisposable && onDocumentClickDisposable.dispose();

	const attachMarkAllAsReadClick = (buttonEl: HTMLElement) =>
		(onMarkedAllAsReadDisposable = expressEventListener(buttonEl, 'click', onMarkedAllAsRead));

	const detachMarkAllAsReadClick = () => onMarkedAllAsReadDisposable && onMarkedAllAsReadDisposable.dispose();

	// init component
	const button = createClickableElement(buttonEl, onButtonClick);

	const eventDisposables = compositeDisposable (
		[
			expressEventListener(closeButtonEl, 'click', onCloseIconClick),
			expressEventListener(window, 'resize', setOverLayClass),
		]
	);

	return {
		dispose: () => {
			eventDisposables.dispose();
			button.dispose();
			detachDocumentClick();
			detachMarkAllAsReadClick();
			closeMenu();
		}
	};
}
