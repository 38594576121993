﻿import {getCookie} from "../utils/cookie";

const trackEvent = (category: string, action: string, eventParams: object, value: number, customParameters?: object) => {
	const dataLayer = (<any>window).dataLayer || [];
	dataLayer.push({
		event: action,
		event_category: category,
		event_label: JSON.stringify(eventParams),
		value: value,
		...customParameters
	});

	if (typeof gtag === 'function') {
		eventParams['event_category'] = category;
		gtag('event', action, eventParams);
	}
	else if (typeof ga === 'function') {
		ga('technical.send', 'event', category, action, JSON.stringify(eventParams), value);
	}
};

interface IOrderStatusParams {
	orderNumber?: string;
	orderStatus?: string;
	paymentStatus?: string;
	pvc?: string;
	repro?: boolean;
}

export function trackOrderStatusEvent(action: string, partialEventParams: IOrderStatusParams, nrOrdersInAccount = -1, nrItemsInOrder = -1) {
	const eventParams = { accountId: getCookie('accid'), ...partialEventParams };
	trackEvent('OrderStatus', action, eventParams, nrOrdersInAccount > 0 ? nrOrdersInAccount : nrItemsInOrder);
}

export function trackContactPageEvent(action: string) {
	trackEvent('ContactPage', action, {}, -1);
}

export function trackSortingOnPop(action: string) {
	trackEvent('SortingOnPOP', action, {}, -1);
}

export function trackComponentEvent(action: string) {
	trackEvent('Component', action, {}, -1);
}

export function trackAddressValidationEvent(action: string) {
	trackEvent('AddressValidation', action, {}, -1);
}

export function trackFiltersEvent(action: string, eventParams?: object) {
	trackEvent('Filters', action, eventParams || {}, -1);
}

export function trackSliderEvent(action: string, eventParams?: object) {
	trackEvent('Slider', action, eventParams || {}, -1);
}

export function trackMgmEvent(action: string) {
	trackEvent('MGMProgram', action, {}, -1);
}

export function trackAccountPrefEvent(action: string, eventParams?: object) {
	trackEvent('AccountPreferences', action, eventParams || {}, -1);
}

export function ProductEvent(action: string, eventParams?: object, customParameters?: object) {
	trackEvent('Product', action, eventParams || {}, -1, customParameters);
}

export interface IRecommendationEventParams {
	pageType?: string;
	pageCode?: string;
	pvc?: string;
}
export function trackRecommendationCartEvent(action: string, recommendationLabel?: IRecommendationEventParams) {
	trackEvent('Recommendations', action, recommendationLabel ? recommendationLabel : {}, -1);
}
